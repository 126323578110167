import { graphql } from "gatsby";
import { ReactElement } from "react";

import withFunnelWrapper from "../../../../features/quotes-funnel/ui/organisms/with-funnel-wrapper/with-funnel-wrapper";
import CardholderName from "../../../../features/quotes-funnel/ui/pages/card/cardholder-name/CardholderName";
import { PageId } from "../../../../settings/pages";

const Page = (): ReactElement => <CardholderName />;

export default withFunnelWrapper(Page, "pg-cardholder-name", PageId.cardholderName);

export const query = graphql`
  query CardholderNamePageQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
