import { Form, useField, useFormikContext } from "formik";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { ReactElement } from "react";

import Button from "../../../../../atoms/button/Button";
import TextFormControl from "../../../../../atoms/text-form-control/TextFormControl";
import { maxCardholderNameLength } from "../../../settings/barkibu_card";
import * as styles from "./CardholderNameForm.module.scss";

interface CardholderNameFormValues {
  hasSubmitError: boolean;
}

const CardholderNameForm = ({ hasSubmitError }: CardholderNameFormValues): ReactElement => {
  const { t } = useTranslation();
  const [field] = useField<string>("cardholderName");
  const { isValid, isSubmitting, setFieldValue } = useFormikContext();

  const isSubmitDisabled = !isValid || !field.value.length || isSubmitting;

  return (
    <Form className={styles.cardholderNameForm}>
      <div>
        <TextFormControl
          {...field}
          label={t("pg_cardholder_name.cardholder_name.field.label")}
          isFocusedOnRender={true}
          onChange={(e) => {
            if (e.target.value.length > maxCardholderNameLength) {
              return;
            }

            setFieldValue("cardholderName", e.target.value);
          }}
        />
        <p className={styles.lengthMessage}>
          {t("pg_cardholder_name.cardholder_name.field.length", {
            cardholderNameLength: field.value.length,
            maxCardholderNameLength,
          })}
        </p>

        {hasSubmitError && <p className={styles.errorMessage}>{t("common.error_message")}</p>}
      </div>
      <Button type="submit" disabled={isSubmitDisabled} isLoading={isSubmitting}>
        {t("common.cta.next")}
      </Button>
    </Form>
  );
};

export default CardholderNameForm;
