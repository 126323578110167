// extracted by mini-css-extract-plugin
export var dialog = "CardholderNameDialog-module--dialog--+WRe9";
export var heading = "CardholderNameDialog-module--heading--rsM1C";
export var content = "CardholderNameDialog-module--content--7bMUQ";
export var closeButton = "CardholderNameDialog-module--closeButton--uXWaa";
export var baiButton = "CardholderNameDialog-module--baiButton--Ec8X9";
export var whatsappIcon = "CardholderNameDialog-module--whatsappIcon--50fb-";
export var messageWrapper = "CardholderNameDialog-module--messageWrapper--IIkOU";
export var firstMessageWrapper = "CardholderNameDialog-module--firstMessageWrapper--T4YO6";
export var thirdMessageWrapper = "CardholderNameDialog-module--thirdMessageWrapper--JO20k";
export var secondMessageWrapper = "CardholderNameDialog-module--secondMessageWrapper--H9aD4";
export var fourthMessageWrapper = "CardholderNameDialog-module--fourthMessageWrapper--CU8ai";