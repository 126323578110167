import { FormikHelpers } from "formik";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { ReactElement, useState } from "react";
import * as Yup from "yup";

import PageStepAnimation from "../../../../../../atoms/page-step-animation/PageStepAnimation";
import { Image } from "../../../../../../core/utils/images";
import PageStepForm from "../../../../../../organisms/page-step-form/PageStepForm";
import {
  useResult,
  useUpdateResult,
} from "../../../../../../organisms/result/use-cases/result-use-cases";
import { Events, track } from "../../../../../../utils/analytics";
import { handleUpdateResultErrors } from "../../../../../../utils/error-utils/catch-error-handlers";
import useTrackPageViewed from "../../../../../../utils/hooks/useTrackPageViewed";
import { maxCardholderNameLength } from "../../../../settings/barkibu_card";
import CardholderNameDialog from "../../../organisms/cardholder-name-dialog/CardholderNameDialog";
import CardholderNameForm from "../../../organisms/cardholder-name-form/CardholderNameForm";
import * as styles from "./CardholderName.module.scss";

interface CardholderNameFormValues {
  cardholderName: string;
}

const cardSVG: Image = require("../../../images/card.svg") as Image;

const cardSVGSrc: string = cardSVG.default;

const CardholderName = (): ReactElement => {
  const { t } = useTranslation();
  const result = useResult();
  const updateResult = useUpdateResult();
  const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);

  const nextUrl = `/results/${result?.uuid}/card/address/`;
  const initialCardHolderName = (): string => {
    if (!result) {
      return "";
    }
    const firstName = result.pet_parent_first_name || "";
    const lastName = result.pet_parent_last_name || "";
    const resultFullName = `${firstName} ${lastName}`;

    return (
      result.barkibu_card_cardholder_name ?? resultFullName.substring(0, maxCardholderNameLength)
    );
  };

  useTrackPageViewed(Events.CARDHOLDER_NAME_VIEWED_BROWSER);

  const cardholderNameFormData = {
    initialValues: {
      cardholderName: initialCardHolderName(),
    },
    validationSchema: Yup.object({
      cardholderName: Yup.string()
        .trim()
        .required(t("common.validation.required"))
        .matches(/^[a-zA-ZÀ-ÿ\s'-]+$/, t("pg_cardholder_name.validation.invalid_characters")),
    }),
    handleSubmit: async (
      values: CardholderNameFormValues,
      { setSubmitting }: FormikHelpers<CardholderNameFormValues>
    ) => {
      if (!updateResult) {
        return;
      }

      setSubmitting(true);
      track(Events.FORM_ANSWERED, {
        eventSender: "Cardholder Name",
        initialName: initialCardHolderName(),
        newName: values.cardholderName,
        nameChanged: initialCardHolderName() !== values.cardholderName,
      });

      try {
        await updateResult({ barkibu_card_cardholder_name: values.cardholderName });
        void navigate(nextUrl);
      } catch (error) {
        handleUpdateResultErrors(error, "cardholder name");
        setHasSubmitError(true);
      } finally {
        setSubmitting(false);
      }
    },
    children: () => {
      return <CardholderNameForm hasSubmitError={hasSubmitError} />;
    },
  };

  return (
    <>
      {result && (
        <PageStepAnimation adoptionClassName={styles.cardholderName}>
          <PageStepForm
            title={t("pg_cardholder_name.title")}
            image={cardSVGSrc}
            subtitle={
              <>
                {t("pg_cardholder_name.subtitle")}{" "}
                <CardholderNameDialog
                  countryCode={result.country}
                  phone={
                    result.pet_parent_phone
                      ? `${result.pet_parent_country_code}${result.pet_parent_phone}`
                      : ""
                  }
                />
              </>
            }
            formData={cardholderNameFormData}
          />
        </PageStepAnimation>
      )}
    </>
  );
};

export default CardholderName;
