import { ReactElement, ReactNode, useRef } from "react";
import { Overlay, useButton, useDialog, useModalOverlay, useOverlayTrigger } from "react-aria";
import { useOverlayTriggerState } from "react-stately";

import { Events, track } from "../../../../../utils/analytics";
import * as styles from "./Dialog.module.scss";

interface DialogProps {
  children: (close: () => void) => ReactNode;
  label: string;
  analyticsLabel?: string;
}

const Dialog = ({ children, label, analyticsLabel, ...props }: DialogProps): ReactElement => {
  const modalRef = useRef(null);
  const buttonRef = useRef(null);

  const state = useOverlayTriggerState({});
  const { triggerProps, overlayProps } = useOverlayTrigger({ type: "dialog" }, state);
  const { dialogProps } = useDialog(props, modalRef);
  const { modalProps } = useModalOverlay(props, state, modalRef);
  const { buttonProps } = useButton(triggerProps, buttonRef);

  const trackDialog = (event: Events) => {
    track(event, { dialog: analyticsLabel });
  };

  const openDialog = () => {
    trackDialog(Events.DIALOG_OPENED);
    state.open();
  };

  const closeDialog = () => {
    trackDialog(Events.DIALOG_CLOSED);
    state.close();
  };

  return (
    <>
      <button {...buttonProps} ref={buttonRef} className={styles.button} onClick={openDialog}>
        {label}
      </button>
      {state.isOpen && (
        <Overlay>
          <div className={styles.modal} {...modalProps} {...overlayProps} ref={modalRef}>
            <div {...dialogProps}>{children(closeDialog)}</div>
          </div>
        </Overlay>
      )}
    </>
  );
};

export default Dialog;
