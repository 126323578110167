import cn from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import React, { ReactElement, useState } from "react";

import Button, { ButtonVariantName } from "../../../../../atoms/button/Button";
import Heading from "../../../../../atoms/heading/Heading";
import { CountryCode } from "../../../../../settings/countries";
import { States } from "../../../../../settings/state";
import { Events, track } from "../../../../../utils/analytics";
import Dialog from "../../molecules/dialog/Dialog";
import * as styles from "./CardholderNameDialog.module.scss";

interface CardholderNameDialogProps {
  countryCode: CountryCode;
  phone: string;
}

const explanation = [
  { key: "pg_cardholder_name.dialog.message_explanation_1", className: styles.firstMessageWrapper },
  {
    key: "pg_cardholder_name.dialog.message_explanation_2",
    className: styles.secondMessageWrapper,
  },
  { key: "pg_cardholder_name.dialog.message_explanation_3", className: styles.thirdMessageWrapper },
  {
    key: "pg_cardholder_name.dialog.message_explanation_4",
    className: styles.fourthMessageWrapper,
  },
];

const CardholderNameDialog = ({ countryCode, phone }: CardholderNameDialogProps): ReactElement => {
  const { t } = useTranslation();
  const [whatsappStatus, setWhatsappStatus] = useState<string>("");

  const handleClick = (): void => {
    setWhatsappStatus(States.LOADING_STATE);
    track(Events.CLICKED_CTA, {
      eventSender: "Barkibu Card Dialog - Bai button",
      countryCode,
      phone: `+${phone}`,
    });

    setTimeout(() => {
      setWhatsappStatus(States.SUCCESS_STATE);
    }, 2000);
  };

  return (
    <Dialog label={t("pg_cardholder_name.dialog.label")}>
      {(close) => {
        const closeDialog = (): void => {
          close();
          setWhatsappStatus("");
        };

        return (
          <section className={styles.dialog}>
            {whatsappStatus !== States.SUCCESS_STATE && (
              <>
                <Heading level={2} adoptionClassName={styles.heading}>
                  {t("pg_cardholder_name.dialog.title")}
                </Heading>
                <div className={styles.content}>
                  {explanation.map((message, index) => (
                    <div key={index} className={cn(styles.messageWrapper, message.className)}>
                      <p>{parse(t(message.key))}</p>
                    </div>
                  ))}
                </div>
              </>
            )}
            {whatsappStatus === States.SUCCESS_STATE && (
              <>
                <svg className={cn("icon", `icon--whatsapp`, styles.whatsappIcon)} role="img">
                  <use href={`#icon-whatsapp`}></use>
                </svg>
                <Heading level={2} adoptionClassName={styles.heading}>
                  {t("pg_cardholder_name.dialog.bai_message.whatsapp_sent")}
                </Heading>
              </>
            )}
            <Button
              type="button"
              adoptionClassName={styles.closeButton}
              onClick={closeDialog}
              disabled={whatsappStatus === States.LOADING_STATE}
            >
              {t("pg_cardholder_name.dialog.close_button")}
            </Button>
            {whatsappStatus !== States.SUCCESS_STATE && (
              <Button
                type="button"
                variantName={ButtonVariantName.secondary}
                adoptionClassName={styles.baiButton}
                onClick={handleClick}
                isLoading={whatsappStatus === States.LOADING_STATE}
              >
                {t("pg_cardholder_name.dialog.bai_button")}
              </Button>
            )}
          </section>
        );
      }}
    </Dialog>
  );
};

export default CardholderNameDialog;
